<div class="page-container audit_activities">
  <p-toast />
  <div class="title">
    <i
      class="pi pi-arrow-left"
      style="font-size: 1rem; cursor: pointer"
      (click)="navigateBack()"
    ></i>
    <h1 class="header">Activities</h1>
  </div>
  <p-table
    [columns]="cols"
    [value]="activitiesList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[5, 10, 20]"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>

      <ng-container *ngIf="isLoading">
        <tr *ngFor="let _ of skeletonRows">
          <th class="skeleton" *ngFor="let col of columns">
            <p-skeleton></p-skeleton>
          </th>
        </tr>
      </ng-container>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-rowData
      let-columns="columns"
      let-i="rowIndex"
      let-editing="editing"
    >
      <tr [pEditableRow]="rowData">
        <td class="job-name" (click)="audit(rowData)">
          {{ rowData.schemeActivityName }}
        </td>
        <td>{{ rowData.schemeName }}</td>
        <td>{{ rowData?.aggregator?.name }}</td>
        <td (click)="op.toggle($event)">
          <img src="assets/ActionMenu.svg" style="cursor: pointer" />
          <p-overlayPanel #op>
            <ng-template pTemplate="content">
              <div
                (mouseover)="handleHover(1)"
                (click)="generatePdf(rowData)"
                class="list-item"
                [class.hover-bg-light-blue]="onHover === 1"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-file-export" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Generate PDF</span>
              </div>
              <div
                (mouseover)="handleHover(2)"
                (click)="downloadPdf(rowData)"
                class="list-item"
                [class.hover-bg-light-green]="onHover === 2"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-file-pdf" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Download PDF</span>
              </div>
            </ng-template>
          </p-overlayPanel>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" *ngIf="activitiesList.length === 0">
      <tr *ngIf="!isLoading">
        <td colspan="6">No Data found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>
