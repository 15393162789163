export function trimFormData(formData: any): any {
  const trimmedData: any = {};
  for (const key in formData) {
    if (formData.hasOwnProperty(key)) {
      const value = formData[key];
      trimmedData[key] = typeof value === 'string' ? value.trim() : value;
    }
  }
  return trimmedData;
}
