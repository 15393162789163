import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  constructor(private api: ApiService) {}

  async fetchFieldData(): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(`api/jobs/GetUpcomingJobsForInstaller`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchAllCertificatePrice(type: string): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(
          `api/InstallerCertificate/marketAndInstallerPrices?certificateName=${type}`
        )
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
