<div class="page-container certificate-container">
  <p-toast />
  <div class="header-container">
    <app-button label="Add" (clickEvent)="handleAddDialog()" class="header" />
  </div>
  <div>
    <p-chart type="line" [data]="data" [options]="options" />
  </div>

  <p-dialog
    header="Header"
    [(visible)]="display"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '30vw' }"
  >
    <ng-template pTemplate="header">
      <h3>Add New</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <form class="dialog-form" [formGroup]="formData">
        <div class="grid-container">
          <app-dropdown
            [options]="CertificateList"
            [control]="formData.get('name')"
            label="Certificate"
            placeholder="Select"
            optionLabel="label"
            optionValue="value"
            [required]="true"
          />
          <app-calendar
            label="Date"
            [control]="formData.get('date')"
            [required]="true"
          />
          <app-number-input
            [control]="formData.get('price')"
            [useGrouping]="false"
            label="Price"
            inputId="minmaxfraction"
            mode="decimal"
            [minFractionDigits]="1"
            [maxFractionDigits]="5"
          />
          <div style="display: flex; align-items: center">
            <p-inputSwitch formControlName="flatPrice" />
            <div style="font-size: 0.9rem; margin-left: 1rem">Percentage</div>
          </div>
          <app-number-input
            *ngIf="formData.value.flatPrice"
            [control]="formData.get('percentage')"
            [useGrouping]="false"
            label="Percentage"
            inputId="minmaxfraction"
            mode="decimal"
            (keyup.enter)="onEnter($event)"
          />
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        label="Save"
        [disabled]="formData.invalid"
        (clickEvent)="onSave()"
      />
    </ng-template>
  </p-dialog>
</div>
