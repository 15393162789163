import { Component } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { AGENT_LIST } from '../../../../mock-data';
import { DataItem, ImageGroup, TableCols } from '../../../types';
import { environment } from '../../../../environments/environment';
import { AUDIT_JOB_COLS } from '../../../constants';
import { ButtonComponent } from '../../../Components/button/button.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropdownComponent } from '../../../Components/dropdown/dropdown.component';
import { TooltipModule } from 'primeng/tooltip';
import { AuditService } from '../audit.service';
import JSZip from 'jszip';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProjectService } from '../../project-management/project.services';
import { MessageService } from 'primeng/api';
import { scheduleService } from '../../schedules/schedule.service';

@Component({
  selector: 'app-job-audit',
  standalone: true,
  templateUrl: './job-audit.component.html',
  styleUrl: './job-audit.component.scss',
  providers: [MessageService],
  imports: [SharedModule, ButtonComponent, DropdownComponent, TooltipModule],
})
export class JobAuditComponent {
  activitiesData: any;
  assessmentCols: TableCols[] = AUDIT_JOB_COLS;
  AssessmentList: any[] = [];
  InstallationList: any[] = [];
  allFields: any = [];
  statusCounts = { pass: 0, reject: 0, empty: 0 };
  rejectDialog: boolean = false;
  formData!: FormGroup;
  AgentList: any[] = [];
  isLoading: boolean = true;
  skeletonRows = new Array(5);
  rowData: any;
  onSaveRejectLoad: boolean = false;
  agentNames!: string[];
  jobData: any;

  constructor(
    private formBuilder: FormBuilder,
    private auditService: AuditService,
    private http: HttpClient,
    private projectService: ProjectService,
    private messageService: MessageService,
    private schuduleService: scheduleService
  ) {
    this.initialForm();
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  initialForm(): void {
    this.formData = this.formBuilder.group({
      reason: ['', Validators.required],
      agent: ['', Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    this.activitiesData = history.state.activitiesData;
    this.jobData = history.state.jobData;
    await this.getScheduleData();
    this.getFields();
  }

  private async getScheduleData(): Promise<any[]> {
    try {
      const payload = {
        jobId: this.activitiesData.jobId,
      };
      const res = await this.schuduleService.fetchScheduleData(payload);
      this.agentNames = res.flatMap((item: any) =>
        item.agents.map((agent: any) => agent.name)
      );
      return res;
    } catch (error: any) {
      return [];
    }
  }

  updateStatusCounts(data: any): void {
    this.statusCounts = data.reduce(
      (counts: any, item: any) => {
        if (item.status === 'pass') {
          counts.pass++;
        } else if (item.status === 'reject') {
          counts.reject++;
        } else {
          counts.empty++;
        }
        return counts;
      },
      { pass: 0, reject: 0, empty: 0 }
    );
  }

  navigateBack(): void {
    window.history.back();
  }

  async onAuditStatus(status: string, rowData: any): Promise<void> {
    if (status === 'reject') {
      this.AgentList = await this.projectService.fetchAgentList();
      this.rejectDialog = true;
      this.rowData = rowData;
    } else if (status === 'pass') {
      if (rowData.jobFieldValues.at(-1).auditDetails) {
        const payload = [
          {
            id: rowData.jobFieldValues.at(-1).auditDetails.id,
            JobFieldValueId: rowData.jobFieldValues.at(-1).id,
            IsFailed: false,
            RejectReason: '',
            AgentId: null,
          },
        ];
        this.updateFieldStatus(payload);
      } else {
        const payload = [
          {
            JobFieldValueId: rowData.jobFieldValues.at(-1).id,
            IsFailed: false,
            RejectReason: '',
            AgentId: null,
          },
        ];
        this.postFieldStatus(payload);
      }
    }
  }

  private async postFieldStatus(payload: any): Promise<void> {
    try {
      const res = await this.auditService.createFieldStatus(
        payload,
        this.activitiesData.id
      );
      if (res) {
        this.rejectDialog = false;
        this.isLoading = false;
        this.onSaveRejectLoad = false;
        this.getFields();
      }
    } catch (error: any) {
      this.getFields();
      this.onSaveRejectLoad = false;
      this.errorToast(error.message);
    }
  }

  private async updateFieldStatus(payload: any): Promise<void> {
    try {
      const res = await this.auditService.updateFieldStatus(
        payload,
        this.activitiesData.id
      );
      if (res) {
        this.rejectDialog = false;
        this.isLoading = false;
        this.onSaveRejectLoad = false;
        this.getFields();
      }
    } catch (error: any) {
      this.getFields();
      this.onSaveRejectLoad = false;
      this.errorToast(error.message);
    }
  }

  clearStatus(rowData: any): void {
    rowData.status = 'empty';
  }

  async onSaveReject(): Promise<void> {
    this.onSaveRejectLoad = true;
    if (this.rowData.jobFieldValues.at(-1).auditDetails) {
      const { reason, agent } = this.formData.value;
      const payload = [
        {
          id: this.rowData.jobFieldValues.at(-1).auditDetails.id,
          JobFieldValueId: this.rowData.jobFieldValues.at(-1).id,
          IsFailed: true,
          RejectReason: reason,
          AgentId: agent,
        },
      ];
      this.updateFieldStatus(payload);
    } else {
      const { reason, agent } = this.formData.value;
      const payload = [
        {
          JobFieldValueId: this.rowData.jobFieldValues.at(-1).id,
          IsFailed: true,
          RejectReason: reason,
          AgentId: agent,
        },
      ];
      this.postFieldStatus(payload);
    }
  }

  onCancelReject(): void {
    this.rejectDialog = false;
    this.initialForm();
  }

  async getFields(): Promise<any[]> {
    try {
      const res = await this.auditService.fetchFieldData(
        this.activitiesData.id
      );
      const photoValuesWithId = this.extractPhotoValues(res);
      const imagesData = await this.downloadAndExtractImages(photoValuesWithId);

      if (imagesData) this.isLoading = false;

      const updatedData = this.updateDataWithImages(res, imagesData);
      this.AssessmentList = updatedData.filter(
        (item: any) => item.stage === 'ASSESSMENT'
      );
      this.InstallationList = updatedData.filter(
        (item: any) => item.stage === 'INSTALLATION'
      );

      this.updateStatusCounts(updatedData);
      this.allFields = updatedData;
      return updatedData;
    } catch (error: any) {
      return [];
    }
  }

  async downloadAndExtractImages(
    imageGroups: ImageGroup[]
  ): Promise<ImageGroup[]> {
    const headers = new HttpHeaders({
      Accept: 'application/zip',
    });

    const result: ImageGroup[] = [];

    for (const group of imageGroups) {
      const payload = { ImagePaths: group.images };

      try {
        const response: any = await this.http
          .post(`${environment.baseUrl}/api/images/fetch`, payload, {
            headers,
            responseType: 'arraybuffer',
          })
          .toPromise();

        const zip = new JSZip();
        await zip.loadAsync(response);
        const imagePromises = Object.keys(zip.files).map(async (filename) => {
          const file = zip.files[filename];
          const blob = await file.async('blob');
          const url = URL.createObjectURL(blob);
          return url;
        });

        const images = await Promise.all(imagePromises);

        result.push({
          id: group.id,
          images: images,
        });
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
    }

    return result;
  }

  private extractPhotoValues(res: any[]): { id: number; images: string[] }[] {
    const data = res
      .filter(
        (item) =>
          item.field.fieldType === 'Photo' ||
          item.field.fieldType === 'Signature'
      )
      .map((item) => {
        return {
          id: item.id,
          images: item.jobFieldValues.at(-1)?.value.split('|'),
        };
      });
    return data;
  }

  private statusUpdate(data: any) {
    if (data?.auditDetails) {
      if (data.auditDetails.isFailed) return 'reject';
      else return 'pass';
    } else {
      return 'empty';
    }
  }

  private updateDataWithImages(
    res: any[],
    imagesData: ImageGroup[]
  ): DataItem[] {
    return res.map((item) => {
      const imageGroup = imagesData.find((group) => group.id === item.id);
      if (imageGroup) {
        return {
          ...item,
          value: imageGroup?.images,
          status: this.statusUpdate(item.jobFieldValues.at(-1)),
        };
      } else {
        return {
          ...item,
          value: item.jobFieldValues.at(-1)?.value || null,
          status: this.statusUpdate(item.jobFieldValues.at(-1)),
        };
      }
    });
  }
}
