import { Component } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { ProjectType, TableCols } from '../../types';
import { AUDIT_COLS } from '../../constants';
import { NavigationExtras, Router } from '@angular/router';
import { ProjectService } from '../project-management/project.services';
import ROUTES from '../../routes.const';

@Component({
  selector: 'app-audit',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './audit.component.html',
  styleUrl: './audit.component.scss',
})
export class AuditComponent {
  cols: TableCols[] = AUDIT_COLS;
  ProjectList: any[] = [];
  isLoading: boolean = true;
  skeletonRows = new Array(10);

  constructor(private router: Router, private service: ProjectService) {}

  async ngOnInit() {
    this.ProjectList = await this.projectData();
  }

  private async projectData(): Promise<ProjectType[]> {
    try {
      const data = await this.service.fetchProjectData(0, 10, '', null);
      if (data) this.isLoading = false;
      const auditJobs = data.data
        .map((project: any) => {
          return {
            ...project,
            jobs: project.jobs.filter((job: any) => job.status === 'AUDIT'),
          };
        })
        .filter((project: any) => project.jobs.length > 0);
      return auditJobs;
    } catch (error: any) {
      this.isLoading = false;
      return [];
    }
  }

  activities(jobData: any): any {
    const params: NavigationExtras = { state: { jobData } };
    this.router.navigate([ROUTES.AUDIT_ACTIVITIES], params);
  }
}
