<div class="input-container">
  <label class="input-label">
    {{ label }}
    <span *ngIf="required" class="required-label">*</span>
  </label>
  <input
    pInputText
    [formControl]="control"
    [placeholder]="placeholder"
    [disabled]="disabled"
  />
  <div
    *ngIf="control.invalid && control.touched && errormsg"
    class="error-message"
  >
    {{ errormsg }}
  </div>
  <div
    *ngIf="control.invalid && control.touched && !errormsg"
    class="error-message"
  >
    {{ label ? label : error }} is required.
  </div>
</div>
