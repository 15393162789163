import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ABN_URL } from '../constants';
import { HttpClient } from '@angular/common/http';
import { ABNDetailsType } from '../types';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private mapUrl = 'https://atlas.microsoft.com/search/address/reverse/json';
  private searchAddressUrl = 'https://atlas.microsoft.com/search/address/json';

  subscriptionKey: string = 'p52YZFE20GwoQ9D5pDfik-K6cy9lspUXh2j2MzZFuCE';

  constructor(private http: HttpClient) { }

  get<T>(url: string): Observable<T> {
    return this.http.get<T>(`${environment.baseUrl}/${url}`);
  }

  post<T>(url: string, data: any): Observable<T> {
    return this.http.post<T>(`${environment.baseUrl}/${url}`, data);
  }

  patch<T>(url: string, data: any): Observable<T> {
    return this.http.patch<T>(`${environment.baseUrl}/${url}`, data);
  }

  put<T>(url: string, data: any): Observable<T> {
    return this.http.put<T>(`${environment.baseUrl}/${url}`, data);
  }

  delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(`${environment.baseUrl}/${url}`);
  }

  getAbnData(abn: number): Observable<ABNDetailsType> {
    if (!abn) {
      throw new Error('Provide an ABN number!');
    }

    const url = `${ABN_URL}&abn=${abn}`;
    return this.http.jsonp(url, 'JSONP_CALLBACK');
  }

  getMap(log: number, lat: number): Observable<any> {
    const url = `${this.mapUrl}?subscription-key=${this.subscriptionKey}&api-version=1.0&query=${log},${lat}`;
    return this.http.get(url);
  }

  searchAddress(address: string): Observable<ABNDetailsType> {
    const url = `${this.searchAddressUrl}?subscription-key=${this.subscriptionKey}&api-version=1.0&query=${address}&countrySet=AU`;
    return this.http.get(url);
  }
}
