import { Component } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { SearchFieldComponent } from '../../Components/searchField/search-text.component';
import { ButtonComponent } from '../../Components/button/button.component';
import { InputComponent } from '../../Components/input/input.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PROJECT_COLS, PROJECT_TYPE } from '../../constants';
import { NumberInputComponent } from '../../Components/number-input/number-input.component';
import { NavigationExtras, Router } from '@angular/router';
import { ProjectService } from './project.services';
import { MessageService } from 'primeng/api';
import { ProjectListType, ProjectType, TableCols } from '../../types';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { debounceTime, lastValueFrom, Subject } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';
import { trimFormData } from '../common-methods';
import { DisabledInputComponent } from '../../Components/disabled-input/disabled-input.component';

@Component({
  selector: 'app-project-management',
  standalone: true,
  templateUrl: './project-management.component.html',
  styleUrl: './project-management.component.scss',
  providers: [MessageService, ApiService],
  imports: [
    SharedModule,
    SearchFieldComponent,
    ButtonComponent,
    InputComponent,
    NumberInputComponent,
    HttpClientModule,
    HttpClientJsonpModule,
    DisabledInputComponent,
  ],
})
export class ProjectManagementComponent {
  cols: TableCols[] = PROJECT_COLS;
  projectList: ProjectListType[] = [];
  onHover: number = 0;
  display: boolean = false;
  formData!: FormGroup;
  autoComplete: any[] = [];
  project_type: ProjectType[] = PROJECT_TYPE;
  deleteDialog: boolean = false;
  rowId: number = 0;
  rowData: ProjectListType | null = null;
  editProject: boolean = false;
  isLoading: boolean = true;
  skeletonRows = new Array(10);
  onSaveLoad: boolean = false;
  projectListClone: ProjectListType[] = [];
  commercialFormData!: FormGroup;
  residentialFormData!: FormGroup;
  filteredAddress: any[] = [];
  projectListCount: number = 0;
  StatusList: any[] = [
    { label: 'NEW' },
    { label: 'ASSESSMENT' },
    { label: 'QUOTE' },
    { label: 'INSTALLATION' },
    { label: 'INVOICE' },
    { label: 'COMPLETED' },
  ];

  first = 0;
  pageNumber: number = 0;
  searchQuery: string[] | null = [];
  pageSize: number = 10;
  searchText: string = '';
  searchSubject: Subject<string> = new Subject<string>();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private service: ProjectService,
    private messageService: MessageService,
    private api: ApiService
  ) {
    this.setupSearch();
    this.initializeForms();
    this.initializeCommercialForm();
    this.initializeResidentialForm();
  }

  async ngOnInit() {
    this.projectList = await this.projectData(0, 10, '', null);
  }

  private setupSearch() {
    this.searchSubject.pipe(debounceTime(500)).subscribe((value: string) => {
      this.searchText = value;
      this.pageNumber = 0;
      this.first = 0;
      this.handleSearch(this.pageNumber, this.pageSize, value, null);
    });
  }

  async abnDetails(val: number) {
    const data = await lastValueFrom(this.api.getAbnData(val));
    return data;
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'NEW':
        return 'tag-new';
      case 'ASSESSMENT':
        return 'tag-assessment';
      case 'QUOTE':
        return 'tag-quote';
      case 'INSTALLATION':
        return 'tag-installation';
      case 'INVOICE':
        return 'tag-invoice';
      case 'COMPLETED':
        return 'tag-complete';
      default:
        return '';
    }
  }

  private async projectData(
    pageNo: number,
    pageSize: number,
    search: string,
    searchStatus: string[] | null
  ): Promise<ProjectListType[]> {
    try {
      const data = await this.service.fetchProjectData(
        pageNo,
        pageSize,
        search,
        searchStatus
      );
      if (data) this.isLoading = false;
      this.projectListClone = data.data;
      this.projectListCount = data.totalCount;
      return data.data;
    } catch (error: any) {
      this.isLoading = false;
      this.errorToast(error.message);
      return [];
    }
  }

  async handleSendQuote(rowData: ProjectListType): Promise<void> {
    try {
      const res = await this.service.sendQuote(rowData.id);
      if (res) {
        this.projectList = await this.projectData(
          this.pageNumber,
          this.pageSize,
          '',
          null
        );
        this.successToast('Quote Send Successfully');
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  async handleSendInvoice(rowData: ProjectListType): Promise<void> {
    try {
      const res = await this.service.sendInvoice(rowData.id);
      if (res) {
        this.projectList = await this.projectData(
          this.pageNumber,
          this.pageSize,
          '',
          null
        );
        this.successToast('Invoice Send Successfully');
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  initializeForms(): void {
    this.formData = this.formBuilder.group({
      type: [this.project_type[0]],
      name: ['', Validators.required],
      notes: ['', Validators.required],
    });
  }

  initializeCommercialForm(): void {
    this.commercialFormData = this.formBuilder.group({
      abn: ['', Validators.required],
      billingAddress: ['', Validators.required],
      companyName: ['', Validators.required],
      projectContact: ['', Validators.required],
      companyEmail: ['', [Validators.required, Validators.email]],
      companyPhone: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{10}$')],
      ],
    });
  }

  initializeResidentialForm(): void {
    this.residentialFormData = this.formBuilder.group({
      billingAddress: ['', Validators.required],
      customerName: ['', Validators.required],
      projectContact: ['', Validators.required],
      customerEmail: ['', [Validators.required, Validators.email]],
      customerPhone: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{10}$')],
      ],
    });
  }

  // Method to disable save and update button
  get buttonDisabled(): boolean {
    const typeValue = this.formData.get('type')?.value.value;
    if (typeValue === 1) {
      return this.residentialFormData.invalid || this.formData.invalid;
    } else {
      return this.commercialFormData.invalid || this.formData.invalid;
    }
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  onProjectTypeChange(type: ProjectType): void {
    this.formData.patchValue({ type });
    this.initializeResidentialForm();
    this.initializeCommercialForm();
  }

  async handleStatusSearch(searchVal: any): Promise<void> {
    if (searchVal?.length > 0) {
      this.projectList = await this.projectData(
        this.pageNumber,
        this.pageSize,
        this.searchText,
        searchVal
      );
    } else {
      this.projectList = await this.projectData(
        this.pageNumber,
        this.pageSize,
        this.searchText,
        null
      );
    }
  }

  async handleSearch(
    pageNo: number,
    pageSize: number,
    val: string,
    searchStatus: string[] | null
  ): Promise<void> {
    try {
      if (val !== '') {
        this.projectList = await this.projectData(
          pageNo,
          pageSize,
          val,
          this.searchQuery
        );
      } else {
        this.projectList = await this.projectData(
          pageNo,
          pageSize,
          '',
          this.searchQuery
        );
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  async handleAddNew(): Promise<void> {
    this.display = true;
    this.editProject = false;
    this.initializeResidentialForm();
    this.initializeCommercialForm();
    this.initializeForms();
  }

  closeDialog(): void {
    this.display = false;
    this.deleteDialog = false;
  }

  async onEnter(event: any): Promise<void> {
    const data = await this.abnDetails(event.target.value);

    if (data.Message) {
      this.errorToast(data.Message);
    } else {
      this.commercialFormData.patchValue({
        companyName: data.EntityName,
      });
    }
  }

  private generatePayload(isUpdate: boolean): any {
    const { type, ...commonData } = this.formData.value;
    const isCommercial = type.value === 0;
    const formData = isCommercial
      ? this.commercialFormData.value
      : this.residentialFormData.value;
    return {
      ...(isUpdate ? this.rowData : {}),
      ...commonData,
      ...formData,
      abn: formData.abn ? formData.abn.toString() : '',
      billingAddress: formData.billingAddress?.address
        ? formData.billingAddress?.address
        : this.rowData?.billingAddress,
      type: type.value,
    };
  }

  async onSave(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const payload = this.generatePayload(false);
      const res = await this.service.createProjectData(trimFormData(payload));
      if (res) {
        this.display = false;
        this.onSaveLoad = false;
        this.projectList = await this.projectData(
          this.pageNumber,
          this.pageSize,
          this.searchText,
          null
        );
        this.successToast('Project Created Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async onUpdate(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const payload = this.generatePayload(true);
      const res = await this.service.updateProjectData(trimFormData(payload));
      if (res) {
        this.display = false;
        this.onSaveLoad = false;
        this.projectList = await this.projectData(
          this.pageNumber,
          this.pageSize,
          this.searchText,
          null
        );
        this.successToast('Project Updated Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  projectDetails(rowData: ProjectListType): void {
    const { name } = rowData;
    const params: NavigationExtras = { state: { rowData } };
    this.router.navigate([`project-management/${name}`], params);
  }

  handleEdit(rowData: ProjectListType): void {
    this.display = true;
    this.rowData = rowData;
    this.editProject = true;

    this.formData.patchValue({
      type: rowData.type === 0 ? this.project_type[0] : this.project_type[1],
      name: rowData.name,
      notes: rowData.notes,
    });
    this.commercialFormData.patchValue({
      ...rowData,
      billingAddress: rowData.billingAddress.freeformAddress,
    });
    this.residentialFormData.patchValue({
      ...rowData,
      billingAddress: rowData.billingAddress.freeformAddress,
    });
  }

  handleDelete(rowData: ProjectListType): void {
    this.deleteDialog = true;
    this.rowId = rowData.id;
  }

  async onDeleteProject(): Promise<void> {
    try {
      const res = await this.service.deleteProjectData(this.rowId);
      if (res) {
        this.successToast('Project Deleted Successfully');
        this.deleteDialog = false;
        this.projectList = await this.projectData(
          this.pageNumber,
          this.pageSize,
          this.searchText,
          null
        );
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  onSearchAddress(event: any): void {
    this.api.searchAddress(event.query).subscribe(
      (res: any) => {
        this.filteredAddress = res.results;
      },
      (error: any) => {
        console.error('Error fetching address:', error);
      }
    );
  }

  async pageChange(event: any): Promise<void> {
    this.pageNumber = event.first / event.rows;
    this.first = event.first;
    if (this.searchQuery?.length !== 0) {
      this.projectList = await this.projectData(
        this.pageNumber,
        this.pageSize,
        this.searchText,
        this.searchQuery
      );
    } else {
      this.projectList = await this.projectData(
        this.pageNumber,
        this.pageSize,
        this.searchText,
        null
      );
    }
  }

  async onPageSizeChange(event: any) {
    this.pageSize = event.value;
    if (this.searchQuery?.length !== 0) {
      this.projectList = await this.projectData(
        this.pageNumber,
        event.value,
        this.searchText,
        this.searchQuery
      );
    } else {
      this.projectList = await this.projectData(
        this.pageNumber,
        event.value,
        this.searchText,
        null
      );
    }
  }
}
