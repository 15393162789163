import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private api: ApiService) {}

  async fetchUserData(): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.get(`api/installer-users`));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createUserData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.post(`api/installer/users/invite`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
