<p-toast />
<div class="page-container job-details">
  <div style="display: flex; justify-content: space-between">
    <div class="title">
      <i
        class="pi pi-arrow-left"
        style="font-size: 1rem; cursor: pointer"
        (click)="navigateBack()"
      ></i>
      <h1 class="header">
        {{ jobData.jobName }} ( {{ jobData.locationName }} ) - (
        {{ jobData.projectId }} - {{ jobData.id }} )
      </h1>
    </div>
    <div class="details-container">
      <div [ngClass]="getStatusClass(jobData.status)">
        {{ jobData.status }}
      </div>
      <app-button
        *ngIf="
          projectData.status == 'ASSESSMENT' || projectData.status == 'NEW'
        "
        label="Schedule Assessment"
        class="details"
        (clickEvent)="toSchedule()"
      />
      <app-button
        *ngIf="
          projectData.status !== 'ASSESSMENT' && projectData.status !== 'NEW'
        "
        label="Schedule Installation"
        class="details"
        [disabled]="scheduleInstallationButton"
        (clickEvent)="toSchedule()"
      />
    </div>
  </div>

  <!-- Schedule Dialog -->
  <p-dialog
    header="Header"
    [(visible)]="ScheduleDialog"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '30vw' }"
  >
    <ng-template pTemplate="header">
      <h3>Schedule</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <form class="dialog-form" [formGroup]="ScheduleForm">
        <!-- <div class="schedule-type">
          <div *ngFor="let type of schedule_type">
            <p-radioButton
              [inputId]="type.label"
              [value]="type"
              formControlName="type"
              (onClick)="onTypeChange(type)"
            />
            <label class="input-label" [for]="type.value" class="ml-2">
              {{ type.label }}
            </label>
          </div>
        </div> -->
        <div class="grid-container">
          <div style="width: 50%">
            <label class="input-label">Agent</label>
            <p-multiSelect
              [options]="AgentList"
              formControlName="agent"
              optionLabel="name"
              placeholder="Select"
              appendTo="body"
              optionValue="agentId"
            />
          </div>
          <div style="width: 50%">
            <app-calendar
              label="Date"
              [control]="ScheduleForm.get('date')"
              [required]="true"
            />
          </div>
        </div>
        <div class="grid-container">
          <div style="width: 50%">
            <label class="input-label">From Time</label>
            <p-calendar
              inputId="calendar-24h"
              formControlName="fromTime"
              [timeOnly]="true"
              hourFormat="24"
              [appendTo]="'body'"
            />
          </div>

          <div style="width: 50%">
            <label class="input-label">To Time</label>
            <p-calendar
              inputId="calendar-24h"
              formControlName="toTime"
              [timeOnly]="true"
              hourFormat="24"
              [appendTo]="'body'"
            />
          </div>
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        *ngIf="
          projectData.status == 'ASSESSMENT' || projectData.status == 'NEW'
        "
        label="Save"
        [disabled]="ScheduleForm.invalid"
        (clickEvent)="onSchedule('Assessment')"
      />
      <app-button
        *ngIf="projectData.status == 'INSTALLATION'"
        label="Save"
        [disabled]="ScheduleForm.invalid"
        (clickEvent)="onSchedule('Installation')"
      />
    </ng-template>
  </p-dialog>

  <!-- Activities -->
  <div>
    <div class="heading">
      <div class="title">Activities</div>
      <app-button
        *ngIf="jobData.status == 'NEW' || jobData.status == 'ACTIVE'"
        label="Add"
        (clickEvent)="handleAddNewActivities()"
      />
    </div>

    <p-table
      [columns]="activityCols"
      [value]="ActivitiesList"
      dataKey="id"
      editMode="row"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{ col.header }}</th>
        </tr>

        <ng-container *ngIf="isLoadingActivity">
          <tr *ngFor="let _ of skeletonRowsActivities">
            <th class="skeleton" *ngFor="let col of columns">
              <p-skeleton></p-skeleton>
            </th>
          </tr>
        </ng-container>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-rowData
        let-columns="columns"
        let-i="rowIndex"
        let-editing="editing"
      >
        <tr [pEditableRow]="rowData">
          <td>{{ rowData.schemeActivityName }}</td>
          <td>{{ rowData.schemeName }}</td>
          <!-- <td>{{ rowData.discount || 0 | currency : "USD" }}</td> -->
          <td [pEditableColumn]="rowData.quantity" style="width: 25%">
            <p-cellEditor style="font-size: 0.9rem">
              <ng-template pTemplate="input">
                <p-dropdown
                  [options]="AggregatorList"
                  [filter]="true"
                  [(ngModel)]="rowData.aggregator"
                  [appendTo]="'body'"
                  optionLabel="name"
                  (onChange)="onChangeAggregatore(rowData)"
                />
              </ng-template>
              <ng-template pTemplate="output">
                <div (click)="onSelectAggregatore(rowData)">
                  <img
                    style="margin-right: 0.5rem"
                    src="assets/edit.icon.svg"
                  />
                  {{ rowData?.aggregator?.name || "Select" }}
                </div>
              </ng-template>
            </p-cellEditor>
          </td>
          <!-- <td style="width: 20%">
            <p-cellEditor style="font-size: 0.9rem">
              <ng-template pTemplate="input">
                <p-dropdown
                  [options]="AggregatorList"
                  [filter]="true"
                  [(ngModel)]="rowData.aggregator"
                  [appendTo]="'body'"
                  optionLabel="name"
                  optionValue="name"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.aggregator || "None" }}
              </ng-template>
            </p-cellEditor>
          </td> -->

          <!-- <td>
            <div>
              <i
                *ngIf="!editing"
                class="pi pi-pencil"
                style="color: #235aff"
                (click)="onRowEditActivity(rowData)"
                pInitEditableRow
              ></i>
              <i
                *ngIf="editing"
                class="pi pi-check"
                (click)="onRowActivitySave(rowData)"
                pSaveEditableRow
                style="color: #30a042; padding-right: 2rem"
              ></i>
              <i
                *ngIf="editing"
                class="pi pi-times"
                (click)="onRowActivityCancel(rowData, i)"
                pCancelEditableRow
                style="color: #ff4342"
              ></i>
            </div>
          </td> -->
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" *ngIf="ActivitiesList.length === 0">
        <tr *ngIf="!isLoadingActivity">
          <td colspan="5">No Data found.</td>
        </tr>
      </ng-template>
    </p-table>

    <p-dialog
      header="Header"
      [(visible)]="addActivities"
      [modal]="true"
      [closable]="false"
      [style]="{ width: '40vw' }"
    >
      <ng-template pTemplate="header">
        <div style="display: flex; width: 100%; justify-content: space-between">
          <h3>Fields</h3>
          <app-search-text (searchEvent)="handleSearch($event)" />
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <p-table
          [columns]="ActivitiesCols"
          dataKey="id"
          [value]="ActivitiesData"
          [(selection)]="selectedRows"
          [paginator]="true"
          [rows]="10"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th style="width: 4rem">
                <p-tableHeaderCheckbox />
              </th>
              <th *ngFor="let col of columns">{{ col.header }}</th>
            </tr>

            <ng-container *ngIf="isActivityLoading">
              <tr *ngFor="let _ of skeletonRows">
                <th class="skeleton">
                  <p-skeleton></p-skeleton>
                </th>
                <th class="skeleton" *ngFor="let col of columns">
                  <p-skeleton></p-skeleton>
                </th>
              </tr>
            </ng-container>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <p-tableCheckbox [value]="rowData" />
              </td>
              <td>{{ rowData.schemeName }}</td>
              <td>{{ rowData.name }}</td>
              <td>{{ rowData.deviceType }}</td>
            </tr>
          </ng-template>
        </p-table>

        <ng-template
          pTemplate="emptymessage"
          *ngIf="ActivitiesData.length === 0"
        >
          <tr *ngIf="!isActivityLoading">
            <td colspan="6">No Data found.</td>
          </tr>
        </ng-template>
      </ng-template>

      <ng-template pTemplate="footer">
        <app-button
          label="Cancel"
          (clickEvent)="closeActivityDialog()"
          className="cancel-dialog"
          style="margin-right: 1rem"
        />
        <app-button
          label="Save"
          (clickEvent)="handleAddActivities()"
          [icon]="onSaveActivityLoad ? 'pi pi-spin pi-spinner' : ''"
        />
      </ng-template>
    </p-dialog>
  </div>

  <!-- Assessment -->
  <app-manage-field-type
    *ngIf="jobData.status !== 'NEW'"
    [data]="AssessmentList"
    name="Assessment"
    [isLoadingField]="isLoadingFields"
  />

  <!-- Bill of Material -->
  <div>
    <div class="heading">
      <div class="title">Bill of Material</div>
      <app-button
        label="Add"
        (clickEvent)="onAddBillMaterial()"
        *ngIf="
          projectData.status !== 'COMPLETED' &&
          projectData.status !== 'INVOICE' &&
          projectData.status !== 'INSTALLATION'
        "
      />
    </div>

    <p-table
      [columns]="billMaterialCols"
      [value]="billMaterialList"
      dataKey="id"
      editMode="row"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{ col.header }}</th>
        </tr>

        <ng-container *ngIf="isLoadingBOM">
          <tr *ngFor="let _ of skeletonRowsBOM">
            <th class="skeleton" *ngFor="let col of columns">
              <p-skeleton></p-skeleton>
            </th>
          </tr>
        </ng-container>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-rowData
        let-i="rowIndex"
        let-editing="editing"
      >
        <tr [pEditableRow]="rowData">
          <td>{{ rowData.deviceType }}</td>
          <td>{{ rowData.manufacturer }}</td>
          <td>{{ rowData.model }}</td>
          <td [pEditableColumn]="rowData.quantity" style="width: 10%">
            <p-cellEditor style="font-size: 0.9rem">
              <ng-template pTemplate="input">
                <p-inputNumber
                  [(ngModel)]="rowData.quantity"
                  mode="decimal"
                  [minFractionDigits]="0"
                  [maxFractionDigits]="2"
                  decrementButtonClass="p-button-danger"
                  incrementButtonClass="p-button-success"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  buttonLayout="horizontal"
                  inputId="horizontal"
                  spinnerMode="horizontal"
                  [showButtons]="true"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.quantity || 0 }}
              </ng-template>
            </p-cellEditor>
            <!-- <p-cellEditor style="font-size: 0.9rem">
              <ng-template pTemplate="input">
                <p-inputNumber [(ngModel)]="rowData.quantity" />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.quantity || 0 }}
                <img style="margin-left: 1rem" src="assets/edit.icon.svg" />
              </ng-template>
            </p-cellEditor> -->
          </td>
          <td>
            {{ rowData.rrp || 0 | currency : "USD" }}
          </td>
          <td
            *ngIf="
              projectData.status !== 'COMPLETED' &&
              projectData.status !== 'INVOICE' &&
              projectData.status !== 'INSTALLATION'
            "
          >
            <div style="display: flex">
              <i
                *ngIf="!editing"
                class="pi pi-pencil"
                style="color: #235aff"
                pInitEditableRow
              ></i>
              <i
                *ngIf="editing"
                class="pi pi-check"
                (click)="onRowBOMSave(rowData)"
                pSaveEditableRow
                style="color: #30a042; padding-right: 2rem"
              ></i>
              <i
                *ngIf="editing"
                class="pi pi-times"
                pCancelEditableRow
                style="color: #ff4342"
              ></i>
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template
        pTemplate="emptymessage"
        *ngIf="billMaterialList.length === 0"
      >
        <tr *ngIf="!isLoadingBOM">
          <td colspan="6">No Data found.</td>
        </tr>
      </ng-template>
    </p-table>

    <p-dialog
      header="Header"
      [(visible)]="billMaterialDialog"
      [modal]="true"
      [closable]="false"
      [style]="{ width: '40vw' }"
    >
      <ng-template pTemplate="header">
        <h3>Catalogue</h3>
      </ng-template>
      <ng-template pTemplate="content">
        <p-table
          [columns]="catalogueCols"
          dataKey="id"
          [value]="CatalogueList"
          [(selection)]="selectedBillRows"
          editMode="row"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th style="width: 4rem">
                <p-tableHeaderCheckbox />
              </th>
              <th *ngFor="let col of columns">{{ col.header }}</th>
            </tr>

            <ng-container *ngIf="isLoadingPrice">
              <tr *ngFor="let _ of skeletonRowsBOM">
                <th class="skeleton">
                  <p-skeleton></p-skeleton>
                </th>
                <th class="skeleton" *ngFor="let col of columns">
                  <p-skeleton></p-skeleton>
                </th>
              </tr>
            </ng-container>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <p-tableCheckbox [value]="rowData" />
              </td>
              <td>{{ rowData.deviceType }}</td>
              <td>{{ rowData.manufacturer }}</td>
              <td>{{ rowData.model }}</td>
              <td>{{ rowData.eligibility.rrp | currency : "USD" }}</td>
              <td [pEditableColumn]="rowData.quantity">
                <p-cellEditor style="font-size: 0.9rem">
                  <ng-template pTemplate="input">
                    <p-inputNumber
                      [(ngModel)]="rowData.quantity"
                      mode="decimal"
                      [minFractionDigits]="0"
                      [maxFractionDigits]="2"
                      decrementButtonClass="p-button-danger"
                      incrementButtonClass="p-button-success"
                      incrementButtonIcon="pi pi-plus"
                      decrementButtonIcon="pi pi-minus"
                      buttonLayout="horizontal"
                      inputId="horizontal"
                      spinnerMode="horizontal"
                      [showButtons]="true"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ rowData.quantity || 1 }}
                    <img style="margin-left: 1rem" src="assets/edit.icon.svg" />
                  </ng-template>
                </p-cellEditor>
              </td>
            </tr>
          </ng-template>
        </p-table>

        <ng-template
          pTemplate="emptymessage"
          *ngIf="CatalogueList.length === 0"
        >
          <tr *ngIf="!isLoadingPrice">
            <td colspan="6">No Data found.</td>
          </tr>
        </ng-template>
      </ng-template>

      <ng-template pTemplate="footer">
        <app-button
          label="Cancel"
          (clickEvent)="onCancelBillMaterial()"
          className="cancel-dialog"
          style="margin-right: 1rem"
        />
        <app-button
          label="Save"
          (clickEvent)="onSaveBillMaterial()"
          [icon]="onLoadingBOM ? 'pi pi-spin pi-spinner' : ''"
        />
      </ng-template>
    </p-dialog>
  </div>

  <!-- Quote -->
  <div>
    <div class="heading">
      <div class="title">Quote</div>
    </div>
    <form
      *ngIf="
        projectData.status !== 'COMPLETED' &&
        projectData.status !== 'INVOICE' &&
        projectData.status !== 'INSTALLATION'
      "
      class="quote-container"
      [formGroup]="quoteForm"
    >
      <app-input placeholder="Name" [control]="quoteForm.get('name')" />
      <app-number-input
        [control]="quoteForm.get('price')"
        mode="decimal"
        [useGrouping]="false"
        placeholder="Amount"
        prefix="$"
        [minFractionDigits]="0"
        [maxFractionDigits]="2"
      />
      <app-button
        *ngIf="!editQuote"
        label="Add"
        [disabled]="quoteForm.invalid"
        (clickEvent)="quoteValueAdd()"
        style="margin-top: 0.3rem"
      />
      <app-button
        *ngIf="editQuote"
        label="Edit"
        [disabled]="quoteForm.invalid"
        (clickEvent)="quoteValueEdit()"
        style="margin-top: 0.3rem"
      />
    </form>

    <p-table [columns]="QuoteCols" [value]="QuoteList">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{ col.header }}</th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-rowData
        let-columns="columns"
        let-i="rowIndex"
      >
        <tr>
          <td>{{ rowData.name }}</td>
          <td>${{ rowData.price }}</td>
          <td
            *ngIf="
              projectData.status !== 'COMPLETED' &&
              projectData.status !== 'INVOICE' &&
              projectData.status !== 'INSTALLATION'
            "
            (click)="op.toggle($event)"
          >
            <img src="assets/ActionMenu.svg" style="cursor: pointer" />
            <p-overlayPanel #op>
              <ng-template pTemplate="content">
                <div
                  (mouseover)="handleHover(1)"
                  (click)="quoteEdit(rowData, i)"
                  class="list-item"
                  [class.hover-bg-light-blue]="onHover === 1"
                  (mouseleave)="onHover = 0"
                >
                  <i class="pi pi-user-edit" style="font-size: 1.3rem"></i>
                  <span style="font-size: 14px">Edit</span>
                </div>
                <div
                  (mouseover)="handleHover(2)"
                  (click)="quoteDelete(rowData)"
                  class="list-item"
                  [class.hover-bg-light-red]="onHover === 2"
                  (mouseleave)="onHover = 0"
                >
                  <i class="pi pi-trash" style="font-size: 1.3rem"></i>
                  <span style="font-size: 14px">Delete</span>
                </div>
              </ng-template>
            </p-overlayPanel>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" *ngIf="QuoteList.length === 0">
        <tr *ngIf="!isLoading">
          <td colspan="6">No Data found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <p-dialog
    header="Header"
    [(visible)]="deleteQuoteDialog"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '30vw' }"
  >
    <ng-template pTemplate="header"> </ng-template>
    <ng-template pTemplate="content">
      <h1>Are you sure you want to delete this Job Quote ?</h1>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button label="Delete" (clickEvent)="onDeleteJobQuote()" />
    </ng-template>
  </p-dialog>

  <!-- Installation -->
  <app-manage-field-type
    *ngIf="
      projectData.status == 'INSTALLATION' ||
      projectData.status == 'INVOICE' ||
      projectData.status == 'COMPLETED'
    "
    [data]="InstallationList"
    name="Installation"
    [isLoadingField]="isLoadingFields"
  />

  <div class="job-footer">
    <app-button label="Save" (clickEvent)="onSaveJob()" />
    <app-button
      label="Cancel"
      className="cancel-dialog"
      (clickEvent)="onCancelJob()"
    />
  </div>
</div>
