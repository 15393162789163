import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-number-input',
  standalone: true,
  imports: [InputNumberModule, ReactiveFormsModule, SharedModule],
  templateUrl: './number-input.component.html',
  styleUrl: './number-input.component.scss',
})
export class NumberInputComponent {
  @Input() label: string = '';
  @Input() error: string = '';
  @Input() placeholder: string = '';
  @Input() required: boolean = false;
  @Input() control!: any;
  @Input() inputId: string = '';
  @Input() mode: string = '';
  @Input() maxFractionDigits: number = 0;
  @Input() minFractionDigits: number = 0;
  @Input() useGrouping: boolean = false;
  @Input() disabled: boolean = false;
  @Input() prefix: string = '';
  @Input() errormsg: string = '';
}
