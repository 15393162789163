export const environment = {
    production: false,
    baseUrl: window.origin,
    msalConfig: {
        auth: {
            clientId: 'b7181bab-3053-42da-8223-9f9028281b72'
        }
    },
    apiConfig: {
        scopes: ['https://themisinstall.onmicrosoft.com/9b427173-0333-4986-bad7-303e803d6045/access_as_user'],
        uri: 'https://install.themis.dev.dact.cloud/api'
    },
    b2cPolicies: {
        names: {
            signUpSignIn: "b2c_1_susi",
            resetPassword: "b2c_1_reset",
            editProfile: "b2c_1_edit_profile"
        },
        authorities: {
            signUpSignIn: {
                authority: 'https://themisinstall.b2clogin.com/themisinstall.onmicrosoft.com/B2C_1_susi'
            },
            resetPassword: {
                authority: 'https://themisinstall.b2clogin.com/themisinstall.onmicrosoft.com/B2C_1_Reset'
            },
            editProfile: {
                authority: "https://themisinstall.b2clogin.com/themisinstall.onmicrosoft.com/B2C_1_ProfileEdit"
            }
        },
        authorityDomain: "themisinstall.b2clogin.com"
    }
};
