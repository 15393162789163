import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ChartModule } from 'primeng/chart';
import { DropDownType } from '../../../types';
import moment from 'moment';
import { DashboardService } from '../dashboard.service';
import { CERTIFICATE_VALUE } from '../../../constants';
import { SharedModule } from '../../../shared.module';

@Component({
  selector: 'app-certificate-value',
  standalone: true,
  imports: [SharedModule, ChartModule],
  providers: [MessageService],
  templateUrl: './certificate-value.component.html',
  styleUrl: './certificate-value.component.scss',
})
export class CertificateValueComponent {
  price: any;
  options: any;
  allPrice: any[] = [];
  CertificateList: DropDownType[] = CERTIFICATE_VALUE;
  certificateType: any = CERTIFICATE_VALUE[0].label;

  constructor(
    private messageService: MessageService,
    private service: DashboardService
  ) {}

  async ngOnInit() {
    this.allPrice = await this.getAllCertificatePrice(this.certificateType);
    this.initializeChartOptions();
    this.updateChartData();
  }

  async getAllCertificatePrice(type: string) {
    try {
      const data = await this.service.fetchAllCertificatePrice(type);
      return data;
    } catch (error: any) {}
  }

  updateChartData() {
    const documentStyle = getComputedStyle(document.documentElement);
    const formattedLabel = this.getLastSixMonthsWithDates();
    const label = this.labelFormat();

    const result = {
      price: {
        marketData: this.allPrice,
        installerData: [],
      },
    };
    const alignedData = this.alignDataWithLabels(result, formattedLabel);
    this.price = {
      labels: label,
      datasets: [
        {
          label: 'Market Price',
          data: alignedData['price'].marketData,
          fill: true,
          borderColor: documentStyle.getPropertyValue('--blue-500'),
          tension: 0.4,
          spanGaps: true,
        },
        {
          label: 'Installer Price',
          data: alignedData['price'].installerData,
          fill: true,
          borderColor: documentStyle.getPropertyValue('--orange-500'),
          tension: 0.4,
          backgroundColor: 'rgba(255,167,38,0.2)',
          spanGaps: true,
        },
      ],
    };
  }

  private alignDataWithLabels(
    data: {
      [key: string]: { marketData: any[]; installerData: any[] };
    },
    labels: string[]
  ) {
    const labelPositions = labels.reduce((acc, label, index) => {
      if (moment(label, 'YYYY-MM-DD', true).isValid()) {
        acc[label] = index;
      }
      return acc;
    }, {} as { [key: string]: number });

    Object.values(data).forEach((item) => {
      const marketData = Array(labels.length).fill(null);
      const installerData = Array(labels.length).fill(null);
      item.marketData.forEach((count, index) => {
        const date = moment(
          item.marketData[index].installerCertificateDate
        ).format('YYYY-MM-DD');
        if (date && labelPositions[date] !== undefined) {
          marketData[labelPositions[date]] = count.marketPrice;
          installerData[labelPositions[date]] = count.installerPrice;
        }
      });
      item.marketData = marketData;
      item.installerData = installerData;
    });

    return data;
  }

  private getLastSixMonthsWithDates(): string[] {
    const result: string[] = [];

    for (let i = 5; i >= 0; i--) {
      const currentMonth = moment().subtract(i, 'months');
      const daysInMonth = currentMonth.daysInMonth();

      for (let day = 1; day <= daysInMonth; day++) {
        result.push(currentMonth.clone().date(day).format('YYYY-MM-DD'));
      }
    }

    return result;
  }

  private labelFormat(): string[] {
    const result: string[] = [];

    for (let i = 5; i >= 0; i--) {
      const currentMonth = moment().subtract(i, 'months');
      const monthLabel = currentMonth.format('MMMM');
      const daysInMonth = currentMonth.daysInMonth();

      for (let day = 1; day <= daysInMonth; day++) {
        result.push(`${monthLabel}`);
      }
    }

    return result;
  }

  initializeChartOptions() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue(
      '--text-color-secondary'
    );
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: { color: textColor },
        },
      },
      scales: {
        x: {
          ticks: {
            source: 'auto',
            maxTicksLimit: 6,
            color: textColorSecondary,
          },
          grid: { color: surfaceBorder, drawBorder: false },
        },
        y: {
          ticks: { color: textColorSecondary },
          grid: { color: surfaceBorder, drawBorder: false },
          beginAtZero: true,
        },
      },
    };
  }

  async onCertificateTypeChange(event: any): Promise<void> {
    this.allPrice = await this.getAllCertificatePrice(event.value);
    this.initializeChartOptions();
    this.updateChartData();
  }
}
