const ROUTES = {
  DASHBOARD: '',
  PROJECT_MANAGEMENT: 'project-management',
  USER_MANAGEMENT: 'user-management',
  CATALOGUE_MANAGEMENT: 'catalogue-management',
  PROJECT_DETAILS: 'project-management/:project',
  JOB_DETAILS: 'project-management/project/job',
  AUDIT: 'audit',
  AUDIT_ACTIVITIES: 'audit/activities',
  AUDIT_DETAILS: 'audit/:id',
  PROFILE_SETTINGS: 'profile-settings',
  SCHEDULES: 'schedules',
  CERTIFICATE_VALUE: 'certificate-value',
  TASK_MANAGEMENT: 'task-management',
};

export default ROUTES;
