import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';

@Injectable({ providedIn: 'root' })
export class AuditService {
  constructor(private api: ApiService) {}

  async fetchFieldData(id: number): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(`api/audit?jobActivityId=${id}`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createFieldStatus(payload: any, id: number): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.post(`api/audit?jobActivityId=${id}`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateFieldStatus(payload: any, id: number): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.put(`api/audit?jobActivityId=${id}`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async generateAuditPdf(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.post(`api/audit/generate-pdf`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
