import { Component } from '@angular/core';
import * as atlas from 'azure-maps-control';
import { DashboardService } from './dashboard.service';
import { CertificateValueComponent } from './certificate-value/certificate-value.component';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CertificateValueComponent, SharedModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent {
  map!: atlas.Map;
  subscriptionKey: string = 'p52YZFE20GwoQ9D5pDfik-K6cy9lspUXh2j2MzZFuCE';
  firstWeekData: any = [];
  nextThreeWeeksData: any = [];
  restData: any = [];


  constructor(private service: DashboardService) {}

  async ngOnInit(): Promise<void> {
    this.initialMapComponent();
  }

  async getUpcomingJobs() {
    try {
      const data = await this.service.fetchFieldData();
      this.firstWeekData = this.locationToArray(data.jobsInGreen);
      this.nextThreeWeeksData = this.locationToArray(data.jobsInYellow);
      this.restData = this.locationToArray(data.jobsInRed);
      return data;
    } catch (error: any) {}
  }

  private locationToArray(jobs: any[]) {
    return jobs.map(({ preciseLocation, ...jobs }) => ({
      ...jobs,
      preciseLocation: preciseLocation.split(',').map(Number).reverse(),
    }));
  }

  private async initialMapComponent(): Promise<void> {
    await this.getUpcomingJobs();
    this.map = new atlas.Map('map', {
      center: [133.7751, -25.2744],
      zoom: 4,
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: this.subscriptionKey,
      },
    });

    this.map.controls.add(
      [
        new atlas.control.PitchControl(),
        new atlas.control.CompassControl(),
        new atlas.control.StyleControl(),
        new atlas.control.ZoomControl(),
      ],
      {
        position: atlas.ControlPosition.TopRight,
      }
    );

    this.map.events.add('ready', () => {
      this.registerIcons();
      this.addPins(this.firstWeekData, 'green-pin', '#0AAE1A');
      this.addPins(this.nextThreeWeeksData, 'yellow-pin', '#F4BE00');
      this.addPins(this.restData, 'red-pin', '#FF0000');
    });
  }

  private registerIcons(): void {
    this.map.imageSprite.add('green-pin', 'assets/location-green.png');
    this.map.imageSprite.add('yellow-pin', 'assets/location-yellow.png');
    this.map.imageSprite.add('red-pin', 'assets/location-red.png');
  }

  private addPins(data: any[], iconName: string, clusterColor: string): void {
    const datasource = new atlas.source.DataSource(undefined, {
      cluster: true,
      clusterMaxZoom: 15,
      clusterRadius: 50,
    });
    this.map.sources.add(datasource);

    data.forEach((item) => {
      const point = new atlas.data.Point(item.preciseLocation);
      const feature = new atlas.data.Feature(point, {
        icon: iconName,
        city: item.jobName,
      });
      datasource.add(feature);
    });

    // Add layer for unclustered points
    const symbolLayer = new atlas.layer.SymbolLayer(datasource, undefined, {
      filter: ['!', ['has', 'point_count']],
      iconOptions: {
        image: ['get', 'icon'],
        anchor: 'center',
        allowOverlap: true,
        size: 1.5,
      },
      textOptions: {
        textField: ['get', 'city'],
        offset: [0, 1.8],
        color: '#000000',
        haloColor: '#ffffff',
        haloWidth: 1,
      },
    });

    const clusterLayer = new atlas.layer.BubbleLayer(datasource, undefined, {
      filter: ['has', 'point_count'],
      radius: ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
      color: clusterColor,
      strokeColor: '#ffffff',
      strokeWidth: 2,
    });

    const clusterTextLayer = new atlas.layer.SymbolLayer(
      datasource,
      undefined,
      {
        filter: ['has', 'point_count'],
        textOptions: {
          textField: ['get', 'point_count_abbreviated'],
          color: '#000000',
          offset: [0, 0.4],
        },
      }
    );

    this.map.layers.add([clusterLayer, clusterTextLayer, symbolLayer]);
  }
}
