import { Component, Input } from '@angular/core';
import { SharedModule } from '../../../../shared.module';
import { ASSESSMENT_COLS } from '../../../../constants';
import { TableCols } from '../../../../types';
import { ProjectService } from '../../project.services';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-manage-field-type',
  standalone: true,
  imports: [SharedModule, TooltipModule],
  templateUrl: './manage-field-type.component.html',
  styleUrl: './manage-field-type.component.scss',
})
export class ManageFieldTypeComponent {
  cols: TableCols[] = ASSESSMENT_COLS;
  @Input() data: any[] = [];
  @Input() name: string = '';
  Boolean: any[] = [
    { name: 'Yes', key: 'yes' },
    { name: 'No', key: 'no' },
  ];
  PicklistData: any[] = [];
  selectedFiles: File[] = [];
  @Input() isLoadingField: boolean = false;
  skeletonRows = new Array(5);

  constructor(private service: ProjectService) {}

  async onFileSelected(event: Event, rowData: any): Promise<void> {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }

    const files = Array.from(input.files);
    this.selectedFiles = files;

    rowData.value = [];
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        rowData.value.push(e.target.result);
      };
      reader.readAsDataURL(file);
    });

    await this.uploadImageValue(rowData);
  }

  async uploadImageValue(rowData: any): Promise<void> {
    const formData = new FormData();
    this.selectedFiles.forEach((file) => {
      formData.append('Images', file, file.name);
    });
    formData.append('JobFieldId', rowData.id.toString());
    try {
      const res = await this.service.createJobFieldsImage(formData);
    } catch (error: any) {}
  }
}
