import { Component } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { InputComponent } from '../../Components/input/input.component';
import { ButtonComponent } from '../../Components/button/button.component';
import { SearchFieldComponent } from '../../Components/searchField/search-text.component';
import { TableCols, UserType } from '../../types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { USER_COLS } from '../../constants';
import { USER_LIST } from '../../../mock-data';
import { CheckboxModule } from 'primeng/checkbox';
import { UserService } from './user.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-user-management',
  standalone: true,
  templateUrl: './user-management.component.html',
  styleUrl: './user-management.component.scss',
  providers: [MessageService],
  imports: [
    SharedModule,
    InputComponent,
    ButtonComponent,
    SearchFieldComponent,
    CheckboxModule,
  ],
})
export class UserManagementComponent {
  cols: TableCols[] = USER_COLS;
  userList: UserType[] = [];
  display: boolean = false;
  formData!: FormGroup;
  onHover: number = 0;
  onSaveLoad: boolean = false;
  isLoading: boolean = true;
  skeletonRows = new Array(10);
  userListClone: UserType[] = [];

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  constructor(
    private formBuilder: FormBuilder,
    private service: UserService,
    private messageService: MessageService
  ) {
    this.initializeForms();
  }

  async ngOnInit() {
    this.userList = await this.userData();
  }

  private async userData(): Promise<UserType[]> {
    try {
      const res = await this.service.fetchUserData();
      if (res) this.isLoading = false;
      this.userListClone = res;
      return res;
    } catch (error: any) {
      this.isLoading = false;
      this.errorToast(error.message);
      return [];
    }
  }

  initializeForms(): void {
    this.formData = this.formBuilder.group({
      name: ['', Validators.required],
      web: [false],
      app: [false],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  statusTag(status: string) {
    return status === 'Active'
      ? 'success'
      : status === 'INVITED'
      ? 'warning'
      : status === 'Deleted'
      ? 'danger'
      : undefined;
  }

  async handleSearch(searchVal: string): Promise<void> {
    this.userList = this.userListClone.filter(
      (item) =>
        item.name.toLowerCase().includes(searchVal.toLowerCase()) ||
        item.email.toLowerCase().includes(searchVal.toLowerCase()) ||
        item.appAccessLevel.toLowerCase().includes(searchVal.toLowerCase())
    );
  }

  handleAddNew(): void {
    this.display = true;
  }

  closeDialog(): void {
    this.display = false;
    this.initializeForms();
  }

  private AppAccessLevel(): string {
    const web = this.formData.get('web')?.value;
    const app = this.formData.get('app')?.value;

    if (web && app) {
      return 'BOTH';
    } else if (web) {
      return 'WEB_ONLY';
    } else if (app) {
      return 'APP_ONLY';
    } else {
      return 'NONE';
    }
  }

  async onSave(): Promise<void> {
    this.onSaveLoad = true;
    const payload = {
      name: this.formData.value.name,
      email: this.formData.value.email,
      AppAccessLevel: this.AppAccessLevel(),
    };
    try {
      const res = await this.service.createUserData(payload);
      if (res) {
        this.userList = await this.userData();
        this.display = false;
        this.onSaveLoad = false;
        this.successToast('User Created Successfully');
      }
    } catch (error: any) {
      this.errorToast(error.error.message);
      this.onSaveLoad = false;
    }
  }

  handleEdit(rowData: UserType): void {
    this.display = true;
    this.formData.patchValue({
      ...rowData,
    });
  }

  handleDelete(rowData: UserType): void {}
}
