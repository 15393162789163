<form style="display: flex" [formGroup]="formData">
  <app-input
    placeholder="Search location..."
    [control]="formData.get('searchQuery')"
    style="width: 90%"
  />
  <app-button (click)="search()" label="Search" style="margin: 0.3rem" />
</form>

<div id="mapContainer"></div>
