<div class="sidebar-container">
  <div
    *ngFor="let item of sideBar"
    [ngClass]="{ 'menu-items': true, active: activeTab === item.label }"
  >
    <div (click)="navigateTo(item.link, item.label)" class="label-container">
      <img [src]="item.icon" width="20px" height="20px" alt="Icon" />
      <div
        [ngClass]="{
          'label-wrapper': true,
          'active-text': activeTab === item.label
        }"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</div>
