import { Component } from '@angular/core';
import { ButtonComponent } from '../../../Components/button/button.component';
import { SharedModule } from '../../../shared.module';
import { DropdownComponent } from '../../../Components/dropdown/dropdown.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalendarComponent } from '../../../Components/calendar/calendar.component';
import { InputComponent } from '../../../Components/input/input.component';
import {
  AgentType,
  AggregatorType,
  DataItem,
  ImageGroup,
  ProjectType,
  SchemeActivityType,
  SchemeType,
  TableCols,
} from '../../../types';
import {
  ACTIVITIES_COLS,
  ACTIVITY_COLS,
  BILL_MATERIAL_COLS,
  CATALOGUE_COLS,
  QUOTE_COLS,
  SCHEDULE_TYPE,
} from '../../../constants';
import { ManageFieldTypeComponent } from './manage-field-type/manage-field-type.component';
import { NumberInputComponent } from '../../../Components/number-input/number-input.component';
import { ProjectService } from '../project.services';
import { MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { SearchFieldComponent } from '../../../Components/searchField/search-text.component';
import { CatalogueService } from '../../catalogue-management/catalogue.service';
import { trimFormData } from '../../common-methods';
import moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import JSZip from 'jszip';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-job-details',
  standalone: true,
  templateUrl: './job-details.component.html',
  styleUrl: './job-details.component.scss',
  providers: [MessageService],
  imports: [
    ButtonComponent,
    SharedModule,
    DropdownComponent,
    CalendarComponent,
    InputComponent,
    ManageFieldTypeComponent,
    NumberInputComponent,
    CalendarModule,
    SearchFieldComponent,
  ],
})
export class JobDetailsComponent {
  jobData: any;
  ScheduleDialog: boolean = false;
  ScheduleForm!: FormGroup;
  AgentList: AgentType[] = [];
  activityCols: TableCols[] = ACTIVITY_COLS;
  ActivitiesList: any[] = [];
  ActivitiesData: any[] = [];
  ActivitiesCols: TableCols[] = ACTIVITIES_COLS;
  selectedRows: any[] = [];
  addActivities: boolean = false;
  AggregatorList: AggregatorType[] = [];
  AssessmentList: any[] = [];
  InstallationList: any[] = [];
  billMaterialCols: TableCols[] = BILL_MATERIAL_COLS;
  billMaterialList: any[] = [];
  billMaterialDialog: boolean = false;
  selectedBillRows: any = null;
  CatalogueList: any[] = [];
  catalogueCols: TableCols[] = CATALOGUE_COLS;
  quoteForm!: FormGroup;
  editQuote: boolean = false;
  QuoteCols: TableCols[] = QUOTE_COLS;
  QuoteList: any[] = [];
  onHover: number = 0;
  clonedActivityList: { [s: string]: any } = {};
  isLoading: boolean = true;
  isActivityLoading: boolean = true;
  skeletonRows = new Array(10);
  skeletonRowsActivities = new Array(2);
  skeletonRowsBOM = new Array(5);
  allActivitiesClone: any[] = [];
  deleteQuoteDialog: boolean = false;
  rowDataQuote: any;
  schedule_type: ProjectType[] = SCHEDULE_TYPE;
  onSaveActivityLoad: boolean = false;
  isLoadingFields: boolean = true;
  isLoadingActivity: boolean = true;
  onLoadingBOM: boolean = false;
  isLoadingBOM: boolean = true;
  isLoadingPrice: boolean = true;
  projectData: any;
  metaData: any;
  scheduleInstallationButton: boolean = false;

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  constructor(
    private formBuilder: FormBuilder,
    private service: ProjectService,
    private messageService: MessageService,
    private catalogueService: CatalogueService,
    private http: HttpClient
  ) {
    this.initializeScheduleForm();
    this.initializeQuoteForm();
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'NEW':
        return 'tag-new';
      case 'ACTIVE':
        return 'tag-active';
      case 'ASSESSMENTCOMPLETE':
        return 'tag-assessment-complete';
      case 'AUDIT':
        return 'tag-audit';
      case 'Audit_Aggregator':
        return 'tag-audit-aggregator';
      case 'SUBMITTED':
        return 'tag-submitted';
      case 'ASSESSMENT':
        return 'tag-job-assessment';
      default:
        return '';
    }
  }

  initializeScheduleForm(): void {
    this.ScheduleForm = this.formBuilder.group({
      type: [this.schedule_type[0]],
      agent: ['', Validators.required],
      date: ['', Validators.required],
      fromTime: [new Date(), Validators.required],
      toTime: [new Date(), Validators.required],
    });
  }

  initializeQuoteForm(): void {
    this.quoteForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      price: ['', Validators.required],
    });
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  async ngOnInit(): Promise<void> {
    this.jobData = history.state.jobData;
    this.projectData = history.state.projectData;
    await this.getJobData();
    await this.getBOM();
    this.QuoteList = await this.getJobQuote();
    this.ActivitiesList = await this.getJobActivities();
    //await this.loadFields();
    await this.getJobFields();
  }

  private async getAggregatoreList(id: number): Promise<AggregatorType[]> {
    try {
      const res = await this.service.fetchAggregatorList(id);
      return res;
    } catch (error: any) {
      return [];
    }
  }

  async onSelectAggregatore(rowData: any): Promise<void> {
    this.AggregatorList = await this.getAggregatoreList(rowData.activityId);
  }

  async onChangeAggregatore(rowData: any): Promise<any[]> {
    const { id, jobId, discount, activityId, aggregator } = rowData;
    try {
      const payload = {
        id,
        jobId,
        discount,
        activityId,
        AggregatorId: aggregator.id,
      };
      const res = await this.service.updateAggregator(payload);
      if (res) this.ActivitiesList = await this.getJobActivities();
      return res;
    } catch (error: any) {
      return [];
    }
  }

  async getJobData(): Promise<any[]> {
    try {
      const res = await this.service.fetchJobDataById(this.jobData.id);
      if (res) {
        this.isLoading = false;
      }
      return res;
    } catch (error: any) {
      return [];
    }
  }

  navigateBack(): void {
    window.history.back();
  }

  closeDialog(): void {
    this.ScheduleDialog = false;
    this.deleteQuoteDialog = false;
  }

  async onSchedule(type: string): Promise<void> {
    const { fromTime, toTime, date, agent } = this.ScheduleForm.value;
    try {
      const payload = {
        AgentIds: agent,
        ProjectId: this.jobData.projectId,
        JobId: this.jobData.id,
        JobType: type,
        ScheduleDate: moment(date).format('YYYY-MM-DD'),
        FromTime: moment(fromTime).format('HH:mm:ss'),
        EndTime: moment(toTime).format('HH:mm:ss'),
      };
      const res = await this.service.scheduleJob(trimFormData(payload));
      if (res) {
        this.ScheduleDialog = false;
        this.successToast('Job Scheduled Successfully');
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  private async getAgentList(): Promise<any[]> {
    try {
      const res = await this.service.fetchAgentList();
      const updatedData = res.map((c: any) => {
        const license = Array.from(new Set(c.licenseTypes.split(', '))).join(
          ', '
        );
        return {
          ...c,
          name: `${c.name} - ${license}`,
        };
      });
      return updatedData;
    } catch (error: any) {
      this.errorToast(error.message);
      return [];
    }
  }

  async toSchedule(): Promise<void> {
    this.ScheduleDialog = true;
    this.initializeScheduleForm();
    this.AgentList = await this.getAgentList();
  }

  onTypeChange(type: ProjectType): void {
    this.ScheduleForm.patchValue({ type });
  }

  async handleAddNewActivities(): Promise<void> {
    this.addActivities = true;
    this.selectedRows = [];
    this.ActivitiesData = await this.getAllActivities();
  }

  async getAllActivities(): Promise<any[]> {
    try {
      const res = await this.service.getAllActivitiesData();
      if (res) this.isActivityLoading = false;
      const data = res.flatMap((scheme: SchemeType) =>
        scheme.schemeActivities.map((activity: SchemeActivityType) => ({
          ...activity,
          schemeName: scheme.name,
        }))
      );
      this.allActivitiesClone = data;
      return data;
    } catch (error: any) {
      this.errorToast(error.message);
      return [];
    }
  }

  async getJobActivities(): Promise<any[]> {
    try {
      const res = await this.service.fetchJobActivitiesData(this.jobData.id);
      if (res) this.isLoadingActivity = false;
      const allHaveAggregatorName = res.every((item: any) => item.aggregator);
      this.scheduleInstallationButton = !allHaveAggregatorName;
      return res;
    } catch (error: any) {
      this.isLoadingActivity = false;
      this.errorToast(error.message);
      return [];
    }
  }

  async handleAddActivities(): Promise<void> {
    this.onSaveActivityLoad = true;
    try {
      const payload = await Promise.all(
        this.selectedRows.map(async (c) => {
          const aggregatorId = await this.getAggregatore(c.id);
          return {
            jobId: this.jobData.id,
            activityId: c.id,
            discount: 0,
            aggregatorId: aggregatorId !== null ? aggregatorId : null,
          };
        })
      );
      const res = await this.service.createJobActivitiesData(payload);
      if (res) {
        this.ActivitiesList = await this.getJobActivities();
        //await this.loadFields();
        this.addActivities = false;
        this.onSaveActivityLoad = false;
        this.successToast('Job Activities Added Successfully');
      }
    } catch (error: any) {
      this.onSaveActivityLoad = false;
      this.errorToast(error.error.message);
    }
  }

  private async getAggregatore(id: number): Promise<number | null> {
    try {
      const data = await this.getAggregatoreList(id);
      if (data.length === 1) {
        return data[0].id;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  closeActivityDialog(): void {
    this.addActivities = false;
  }

  async onSaveJob(): Promise<void> {
    const fieldData = this.AssessmentList.concat(this.InstallationList);

    const value = fieldData.filter(
      (item) =>
        item.field.fieldType !== 'Photo' || item.field.fieldType === 'Signature'
    );

    const payload = value.reduce((acc, item) => {
      if (
        item.value !== undefined &&
        item.value !== 'null' &&
        item.value !== '' &&
        item.value !== null
      ) {
        const fieldPayload: { JobFieldId: any; value: any; id?: any } = {
          JobFieldId: item.id,
          value:
            typeof item.value !== 'string' ? String(item.value) : item.value,
        };

        const lastJobFieldId = item.jobFieldValues.at(-1)?.id;
        if (lastJobFieldId) {
          fieldPayload.id = lastJobFieldId;
        }

        acc.push(fieldPayload);
      }
      return acc;
    }, []);

    try {
      const res = await this.service.createJobFieldsValue(payload);
      if (res) {
        window.history.back();
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  async downloadAndExtractImages(
    imageGroups: ImageGroup[]
  ): Promise<ImageGroup[]> {
    const headers = new HttpHeaders({
      Accept: 'application/zip',
    });

    const result: ImageGroup[] = [];

    for (const group of imageGroups) {
      const payload = { ImagePaths: group.images };

      try {
        const response: any = await this.http
          .post(`${environment.baseUrl}/api/images/fetch`, payload, {
            headers,
            responseType: 'arraybuffer',
          })
          .toPromise();

        const zip = new JSZip();
        await zip.loadAsync(response);
        const imagePromises = Object.keys(zip.files).map(async (filename) => {
          const file = zip.files[filename];
          const blob = await file.async('blob');
          const url = URL.createObjectURL(blob);
          return url;
        });

        const images = await Promise.all(imagePromises);

        result.push({
          id: group.id,
          images: images,
        });
      } catch (error) {
        console.error(error);
      }
    }

    return result;
  }

  onCancelJob(): void {
    window.history.back();
  }

  private async getCataloguePrice(): Promise<any[]> {
    try {
      const res = await this.catalogueService.fetchPriceData();
      if (res) this.isLoadingPrice = false;
      return res;
    } catch (error: any) {
      this.isLoadingPrice = false;
      this.errorToast(error.message);
      return [];
    }
  }

  async onAddBillMaterial(): Promise<void> {
    this.billMaterialDialog = true;
    this.selectedBillRows = null;
    this.CatalogueList = await this.getCataloguePrice();
  }

  onCancelBillMaterial(): void {
    this.billMaterialDialog = false;
  }

  async onSaveBillMaterial(): Promise<void> {
    this.onLoadingBOM = true;
    try {
      const payload = this.selectedBillRows.map((c: any) => {
        return {
          projectId: this.jobData.projectId,
          jobId: this.jobData.id,
          catalougeId: c.id,
          model: c.model,
          manufacturer: c.manufacturer,
          deviceType: c.deviceType,
          rrp: c.eligibility.rrp,
          quantity: c.quantity ?? 1,
        };
      });
      const res = await this.service.createJobBOM(payload);
      if (res) {
        await this.getBOM();
        this.billMaterialDialog = false;
        this.onLoadingBOM = false;
        this.successToast('Bill of Material Added Successfully');
      }
    } catch (error: any) {
      this.onLoadingBOM = false;
      this.errorToast(error.message);
    }
  }

  private async getBOM(): Promise<any[]> {
    try {
      const payload = {
        projectId: this.jobData.projectId,
        jobId: this.jobData.id,
      };
      const res = await this.service.fetchJobBOM(payload);
      if (res) {
        this.isLoadingBOM = false;
        this.billMaterialList = res;
      }
      return res;
    } catch (error: any) {
      this.isLoadingBOM = false;
      this.errorToast(error.message);
      return [];
    }
  }

  private async getJobQuote(): Promise<any[]> {
    try {
      const res = await this.service.fetchJobQuote(this.jobData.id);
      return res;
    } catch (error: any) {
      this.errorToast(error.message);
      return [];
    }
  }

  private async loadFields(): Promise<void> {
    try {
      const payload = {
        JobId: this.jobData.id,
        ActivityIds: this.ActivitiesList.map((item) => item.id),
      };
      const res = await this.service.loadJobFields(payload);
      if (res) {
        await this.getJobFields();
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  private async getJobFields(): Promise<DataItem[]> {
    try {
      const res = await this.service.fetchJobFields(this.jobData.id);

      const response = res.filter(
        (item: any) => item.field.fieldType !== 'Constant'
      );

      const photoValuesWithId = this.extractPhotoValues(response);
      const imagesData = await this.downloadAndExtractImages(photoValuesWithId);

      if (imagesData) this.isLoadingFields = false;

      const data = this.getFieldValueDropdown(response);

      const updatedData = this.updateDataWithImages(data, imagesData);

      this.AssessmentList = updatedData.filter(
        (item: any) => item.stage === 'ASSESSMENT'
      );
      this.InstallationList = updatedData.filter(
        (item: any) => item.stage === 'INSTALLATION'
      );

      return updatedData;
    } catch (error: any) {
      this.isLoadingFields = false;
      this.errorToast(error.message);
      return [];
    }
  }

  private getFieldValueDropdown(res: any) {
    const modifiedData = res.map((item: any) => {
      let fieldDropdown = [];

      if (
        item.field &&
        item.field.fieldValues &&
        item.field.fieldValues.length > 0
      ) {
        fieldDropdown = JSON.parse(item.field.fieldValues.at(-1).values);
      }

      return {
        ...item,
        fieldDropdown,
      };
    });

    return modifiedData;
  }

  private extractPhotoValues(res: any[]): { id: number; images: string[] }[] {
    const data = res
      .filter(
        (item) =>
          item.field.fieldType === 'Photo' ||
          item.field.fieldType === 'Signature'
      )
      .map((item) => {
        return {
          id: item.id,
          images: item.jobFieldValues.at(-1)?.value.split('|'),
        };
      });
    return data;
  }

  private updateDataWithImages(
    res: any[],
    imagesData: ImageGroup[]
  ): DataItem[] {
    return res.map((item) => {
      let metaData: any = '';

      if (item.jobFieldValues[0]?.metadata) {
        const decodedString = item.jobFieldValues[0]?.metadata;

        try {
          const jsonArray = JSON.parse(decodedString);
          const parsedData = jsonArray.map((item: any) => JSON.parse(item));
          metaData = parsedData.map((c: any) => ({
            GPSLatitude: c['GPS Latitude'] ?? '',
            GPSLongitude: c['GPS Longitude'] ?? '',
            DateTime: c['Date/Time'] ?? '',
          }));
        } catch (error) {
          console.error('Error parsing metadata:', error);
        }
      }

      const imageGroup = imagesData.find((group) => group.id === item.id);
      if (imageGroup) {
        return { ...item, value: imageGroup?.images, metaData };
      } else {
        return {
          ...item,
          value:
            item.field.fieldType === 'Constant'
              ? item.fieldDropdown
              : item.jobFieldValues.at(-1)?.value || null,
        };
      }
    });
  }

  async quoteValueAdd(): Promise<void> {
    try {
      const payload = {
        name: this.quoteForm.value.name,
        price: this.quoteForm.value.price.toString(),
        jobId: this.jobData.id,
      };
      const res = await this.service.createJobQuote(payload);
      if (res) {
        this.QuoteList = await this.getJobQuote();
        this.initializeQuoteForm();
        this.successToast('Job Quote Added Successfully');
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  async quoteValueEdit(): Promise<void> {
    try {
      const payload = {
        name: this.quoteForm.value.name,
        price: this.quoteForm.value.price.toString(),
        jobId: this.jobData.id,
        id: this.rowDataQuote.id,
      };
      const res = await this.service.updateJobQuote(payload);
      if (res) {
        this.editQuote = false;
        this.initializeQuoteForm();
        this.QuoteList = await this.getJobQuote();
        this.successToast('Job Quote Updated Successfully');
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  quoteEdit(rowData: any, i: number): void {
    this.editQuote = true;
    this.rowDataQuote = rowData;
    this.quoteForm.patchValue({
      name: rowData.name,
      price: rowData.price,
    });
  }

  quoteDelete(rowData: any): void {
    this.rowDataQuote = rowData;
    this.deleteQuoteDialog = true;
  }

  async onDeleteJobQuote(): Promise<void> {
    try {
      const res = await this.service.deleteJobQuote(this.rowDataQuote.id);
      if (res) {
        this.deleteQuoteDialog = false;
        this.QuoteList = await this.getJobQuote();
        this.successToast('Job Quote Deleted Successfully');
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  onRowEditActivity(rowData: any) {
    this.clonedActivityList[rowData.id as string] = { ...rowData };
  }

  onRowActivitySave(rowData: any) {
    if (rowData.price > 0) {
      delete this.clonedActivityList[rowData.id as string];
    } else {
    }
  }

  onRowActivityCancel(rowData: any, index: number) {
    this.ActivitiesList[index] = this.clonedActivityList[rowData.id as string];
    delete this.clonedActivityList[rowData.id as string];
  }

  async handleSearch(searchVal: string): Promise<void> {
    this.ActivitiesData = this.allActivitiesClone.filter(
      (item) =>
        item.schemeName.toLowerCase().includes(searchVal.toLowerCase()) ||
        item.name.toLowerCase().includes(searchVal.toLowerCase()) ||
        item.deviceType.toLowerCase().includes(searchVal.toLowerCase())
    );
  }

  async onRowBOMSave(rowData: any): Promise<void> {
    try {
      const payload = {
        ...rowData,
        rrp: rowData.rrp * rowData.quantity,
      };
      const data = await this.service.updateJobBOM([payload]);
      if (data) {
        await this.getBOM();
        this.successToast('Quantity Updated Successfully');
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }
}
