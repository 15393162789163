<div class="page-container audit-container">
  <p-table
    [columns]="cols"
    [value]="ProjectList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'min-width': '50rem' }"
    [rowsPerPageOptions]="[5, 10, 20]"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>
      <ng-container *ngIf="isLoading">
        <tr *ngFor="let _ of skeletonRows">
          <th class="skeleton" *ngFor="let col of columns">
            <p-skeleton></p-skeleton>
          </th>
        </tr>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr *ngFor="let jobs of rowData.jobs; let isFirstRow = first">
        <td *ngIf="isFirstRow" [attr.rowspan]="rowData.jobs.length">
          {{ rowData.name }}
        </td>
        <td class="job-name" (click)="activities(jobs)">
          {{ jobs.jobName }}
        </td>
        <td>{{ jobs.locationName }}</td>
        <td>{{ jobs.dwellingType }}</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" *ngIf="ProjectList.length === 0">
      <tr *ngIf="!isLoading">
        <td colspan="5">No Data found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>
