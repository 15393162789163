import { Routes } from '@angular/router';
import ROUTES from './routes.const';
import { DashboardComponent } from './Pages/dashboard/dashboard.component';
import { ProjectManagementComponent } from './Pages/project-management/project-management.component';
import { ProjectDetailsComponent } from './Pages/project-management/project-details/project-details.component';
import { JobDetailsComponent } from './Pages/project-management/job-details/job-details.component';
import { AuditComponent } from './Pages/audit/audit.component';
import { JobAuditComponent } from './Pages/audit/job-audit/job-audit.component';
import { CatalogueManagementComponent } from './Pages/catalogue-management/catalogue-management.component';
import { UserManagementComponent } from './Pages/user-management/user-management.component';
import { ProfileSettingsComponent } from './Pages/profile-settings/profile-settings.component';
import { LayoutComponent } from './Layout/layout.component';
import { SchedulesComponent } from './Pages/schedules/schedules.component';
import { ActivitiesAuditComponent } from './Pages/audit/activities/activities.component';
import { MsalGuard } from '@azure/msal-angular';
import { CertificateValueComponent } from './Pages/certificate-value/certificate-value.component';
import { TaskManagementComponent } from './Pages/task-management/task-management.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: ROUTES.DASHBOARD, component: DashboardComponent, canActivate: [MsalGuard] },
      {
        path: ROUTES.PROJECT_MANAGEMENT,
        component: ProjectManagementComponent,
        canActivate: [MsalGuard]
      },
      { path: ROUTES.USER_MANAGEMENT, component: UserManagementComponent, canActivate: [MsalGuard] },
      {
        path: ROUTES.CATALOGUE_MANAGEMENT,
        component: CatalogueManagementComponent,
        canActivate: [MsalGuard]
      },
      { path: ROUTES.PROJECT_DETAILS, component: ProjectDetailsComponent, canActivate: [MsalGuard] },
      { path: ROUTES.JOB_DETAILS, component: JobDetailsComponent, canActivate: [MsalGuard] },
      { path: ROUTES.AUDIT, component: AuditComponent, canActivate: [MsalGuard] },
      { path: ROUTES.AUDIT_ACTIVITIES, component: ActivitiesAuditComponent, canActivate: [MsalGuard] },
      { path: ROUTES.AUDIT_DETAILS, component: JobAuditComponent, canActivate: [MsalGuard] },
      { path: ROUTES.PROFILE_SETTINGS, component: ProfileSettingsComponent, canActivate: [MsalGuard] },
      { path: ROUTES.SCHEDULES, component: SchedulesComponent, canActivate: [MsalGuard] },
      {
        path: ROUTES.CERTIFICATE_VALUE,
        component: CertificateValueComponent,
        canActivate: [MsalGuard],
      },
      {
        path: ROUTES.TASK_MANAGEMENT,
        component: TaskManagementComponent,
        canActivate: [MsalGuard],
      },
    ],
  },
];
