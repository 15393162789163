<div class="layout-container">
  <div class="layout-wrapper">
    <app-header style="width: 100%" />
  </div>
  <div class="layout-wrapper">
    <div class="sidebar-wrapper">
      <app-side-bar />
    </div>
    <div class="content-wrapper">
      <router-outlet />
    </div>
  </div>
</div>
