<div class="page-container audit-details">
  <p-toast />
  <div class="flex">
    <div style="width: 80%">
      <div>
        <div class="title">
          <i
            class="pi pi-arrow-left"
            style="font-size: 1rem; cursor: pointer"
            (click)="navigateBack()"
          ></i>
          <h1 class="header">
            Audit : {{ activitiesData.schemeActivityName }}
          </h1>
        </div>
        <div class="grid-container">
          <div class="details">
            <h4>Assessment Completed :</h4>
            {{ jobData.assessmentDate }}
          </div>
          <div class="details">
            <h4>Installation Completed :</h4>
            {{ jobData.installationDate }}
          </div>
          <div class="details">
            <h4>Agents :</h4>
            {{ agentNames }}
          </div>
          <!-- <div class="details">
            <h4>With :</h4>
            New Steam Boiler
          </div> -->
        </div>
      </div>

      <!-- Assessment Table -->
      <div>
        <div class="heading">
          <div class="title">Assessment</div>
        </div>
        <p-table [columns]="assessmentCols" [value]="AssessmentList">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">{{ col.header }}</th>
            </tr>

            <ng-container *ngIf="isLoading">
              <tr *ngFor="let _ of skeletonRows">
                <th class="skeleton" *ngFor="let col of columns">
                  <p-skeleton></p-skeleton>
                </th>
              </tr>
            </ng-container>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr *ngIf="rowData.status !== 'pass'">
              <td style="width: 40%">{{ rowData.field.description }}</td>
              <td style="width: 30%">
                <div
                  *ngIf="
                    rowData.field.fieldType === 'Photo' ||
                    rowData.field.fieldType === 'Signature'
                  "
                  style="display: flex; gap: 0.5rem"
                >
                  <div *ngFor="let image of rowData.value">
                    <p-image
                      *ngIf="rowData.value"
                      [src]="image"
                      alt=""
                      width="40px"
                      height="30px"
                      [preview]="true"
                    />
                  </div>
                  <div *ngIf="!rowData.value">None</div>
                </div>
                <div
                  style="font-size: 0.9rem"
                  *ngIf="
                    rowData.field.fieldType !== 'Photo' &&
                    rowData.field.fieldType !== 'Signature'
                  "
                >
                  {{ rowData?.value }}
                </div>
              </td>
              <td style="width: 10%">
                <p-radioButton
                  inputId="reject"
                  value="reject"
                  [(ngModel)]="rowData.status"
                  (onClick)="onAuditStatus('reject', rowData)"
                  class="reject-radio-button"
                />
              </td>
              <td style="width: 10%">
                <p-radioButton
                  inputId="pass"
                  value="pass"
                  [(ngModel)]="rowData.status"
                  (onClick)="onAuditStatus('pass', rowData)"
                />
              </td>
              <td style="width: 5%">
                <div *ngIf="rowData.status === 'reject'">
                  <img
                    src="assets/notes.icon.svg"
                    [pTooltip]="tooltipContent"
                    tooltipPosition="bottom"
                  />
                  <ng-template #tooltipContent>
                    <div class="tool-tip">
                      <div style="padding: 0.5rem">
                        <h1>
                          {{
                            rowData.jobFieldValues.at(-1).auditDetails?.agent
                              ?.name
                          }}
                        </h1>
                        <h1>Reason :</h1>
                        <div>
                          {{
                            rowData.jobFieldValues.at(-1).auditDetails
                              ?.rejectReason
                          }}
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </td>
              <td style="width: 5%">
                <i class="pi pi-undo" (click)="clearStatus(rowData)"></i>
              </td>
            </tr>
          </ng-template>

          <ng-template
            pTemplate="emptymessage"
            *ngIf="AssessmentList.length === 0"
          >
            <tr *ngIf="!isLoading">
              <td colspan="5">No Data found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <!-- Installation Table -->
      <div>
        <div class="heading">
          <div class="title">Installation</div>
        </div>
        <p-table [columns]="assessmentCols" [value]="InstallationList">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">{{ col.header }}</th>
            </tr>

            <ng-container *ngIf="isLoading">
              <tr *ngFor="let _ of skeletonRows">
                <th class="skeleton" *ngFor="let col of columns">
                  <p-skeleton></p-skeleton>
                </th>
              </tr>
            </ng-container>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr *ngIf="rowData.status !== 'pass'">
              <td style="width: 40%">{{ rowData.field.description }}</td>
              <td style="width: 30%">
                <div
                  *ngIf="
                    rowData.field.fieldType === 'Photo' ||
                    rowData.field.fieldType === 'Signature'
                  "
                  style="display: flex; gap: 0.5rem"
                >
                  <div *ngFor="let image of rowData.value">
                    <p-image
                      *ngIf="rowData.value"
                      [src]="image"
                      alt=""
                      width="40px"
                      height="30px"
                      [preview]="true"
                    />
                  </div>
                  <div *ngIf="!rowData.value">None</div>
                </div>
                <div
                  style="font-size: 0.9rem"
                  *ngIf="
                    rowData.field.fieldType !== 'Photo' &&
                    rowData.field.fieldType !== 'Signature'
                  "
                >
                  {{ rowData?.value }}
                </div>
              </td>
              <td style="width: 10%">
                <p-radioButton
                  inputId="reject"
                  value="reject"
                  [(ngModel)]="rowData.status"
                  (onClick)="onAuditStatus('reject', rowData)"
                  class="reject-radio-button"
                />
              </td>
              <td style="width: 10%">
                <p-radioButton
                  inputId="pass"
                  value="pass"
                  [(ngModel)]="rowData.status"
                  (onClick)="onAuditStatus('pass', rowData)"
                />
              </td>
              <td style="width: 5%">
                <div *ngIf="rowData.status === 'reject'">
                  <img
                    src="assets/notes.icon.svg"
                    [pTooltip]="tooltipContent"
                    tooltipPosition="bottom"
                  />
                  <ng-template #tooltipContent>
                    <div class="tool-tip">
                      <div style="padding: 0.5rem">
                        <h1>
                          {{
                            rowData.jobFieldValues.at(-1).auditDetails?.agent
                              ?.name
                          }}
                        </h1>
                        <h1>Reason :</h1>
                        <div>
                          {{
                            rowData.jobFieldValues.at(-1).auditDetails
                              ?.rejectReason
                          }}
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </td>
              <td style="width: 5%">
                <i class="pi pi-undo" (click)="clearStatus(rowData)"></i>
              </td>
            </tr>
          </ng-template>

          <ng-template
            pTemplate="emptymessage"
            *ngIf="InstallationList.length === 0"
          >
            <tr *ngIf="!isLoading">
              <td colspan="5">No Data found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <!-- Audit Count -->
    <div style="width: 20%">
      <div class="count-container">
        <div class="red-container">{{ statusCounts.pass }}</div>
        <div class="yellow-container">
          {{ statusCounts.empty }}
        </div>
        <div class="green-container">{{ statusCounts.reject }}</div>
      </div>
    </div>
  </div>

  <div>
    <div class="heading">
      <div class="title">Item Passed</div>
    </div>
    <p-table [value]="allFields">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>Name</th>
          <th>Value</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr *ngIf="rowData.status === 'pass'">
          <td style="width: 50%">{{ rowData.field.description }}</td>
          <td style="width: 30%">
            <div
              *ngIf="
                rowData.field.fieldType === 'Photo' ||
                rowData.field.fieldType === 'Signature'
              "
              style="display: flex; gap: 0.5rem"
            >
              <div *ngFor="let image of rowData.value">
                <p-image
                  *ngIf="rowData.value"
                  [src]="image"
                  alt=""
                  width="40px"
                  height="30px"
                  [preview]="true"
                />
              </div>
              <div *ngIf="!rowData.value">None</div>
            </div>
            <div
              style="font-size: 0.9rem"
              *ngIf="
                rowData.field.fieldType !== 'Photo' &&
                rowData.field.fieldType !== 'Signature'
              "
            >
              {{ rowData?.value }}
            </div>
          </td>
          <td><i class="pi pi-undo" (click)="clearStatus(rowData)"></i></td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="4">No Data found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <!-- Reject Dialog -->
  <p-dialog
    header="Header"
    [(visible)]="rejectDialog"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '30vw' }"
  >
    <ng-template pTemplate="header">
      <h3>Reject</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <form [formGroup]="formData">
        <div>
          <label class="input-label">
            Notes
            <span class="required-label">*</span>
          </label>
          <textarea
            rows="2"
            cols="30"
            pInputTextarea
            formControlName="reason"
            class="textarea"
          ></textarea>
          <div
            class="error-message"
            *ngIf="
              formData.get('reason')?.invalid && formData.get('reason')?.touched
            "
          >
            Notes is required.
          </div>
        </div>

        <app-dropdown
          [options]="AgentList"
          [control]="formData.get('agent')"
          label="Agent"
          [required]="true"
          placeholder="Select"
          optionLabel="name"
          optionValue="agentId"
        />
      </form>
    </ng-template>

    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        className="cancel-dialog"
        style="margin-right: 1rem"
        (clickEvent)="onCancelReject()"
      />
      <app-button
        label="Save"
        (clickEvent)="onSaveReject()"
        [icon]="onSaveRejectLoad ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>

  <!-- <div class="audit-footer">
    <app-button label="Save" (clickEvent)="onSaveAudit()" />
    <app-button
      label="Cancel"
      className="cancel-dialog"
      (clickEvent)="onCancelAudit()"
    />
  </div> -->
</div>
