import { Component } from '@angular/core';
import { SharedModule } from '../../shared.module';
import moment from 'moment';
import { AGENT_LIST, SCHEDULE_DATA } from '../../../mock-data';
import { SCHEDULE_SLOT_TIME } from '../../constants';
import {
  DaySchedule,
  ScheduleData,
  ScheduleItem,
  ScheduleOriginalData,
  scheduleTimeLabel,
} from '../../types';
import { DropdownComponent } from '../../Components/dropdown/dropdown.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { ButtonComponent } from '../../Components/button/button.component';
import { InputComponent } from '../../Components/input/input.component';
import { CalendarComponent } from '../../Components/calendar/calendar.component';
import { scheduleService } from './schedule.service';
import { ProjectService } from '../project-management/project.services';

@Component({
  selector: 'app-schedules',
  standalone: true,
  templateUrl: './schedules.component.html',
  styleUrl: './schedules.component.scss',
  imports: [
    SharedModule,
    DropdownComponent,
    CalendarModule,
    ButtonComponent,
    InputComponent,
    CalendarComponent,
  ],
})
export class SchedulesComponent {
  hoursPerDay: scheduleTimeLabel[] = SCHEDULE_SLOT_TIME;
  scheduleData: DaySchedule[] = [];
  fromTime: any;
  toTime: any;
  AgentList: any[] = [];
  formData!: FormGroup;
  display: boolean = false;
  dialogForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private service: scheduleService,
    private projectService: ProjectService
  ) {
    this.formData = this.formBuilder.group({
      agent: ['', Validators.required],
      dateRange: ['', Validators.required],
    });

    this.dialogForm = this.formBuilder.group({
      notes: [''],
      date: [''],
      fromTime: [''],
      toTime: [''],
    });
  }

  async ngOnInit() {
    this.AgentList = await this.projectService.fetchAgentList();
    if (this.AgentList) {
      this.formData.patchValue({
        agent: this.AgentList[0],
        dateRange: [new Date(), new Date()],
      });
      this.scheduleData = await this.getScheduleData();
    }
  }

  private async getScheduleData(): Promise<any[]> {
    try {
      const { agent, dateRange } = this.formData.value;
      const payload = {
        AgentId: agent.agentId,
        StartDate: moment(dateRange[0]).format('YYYY-MM-DD'),
        EndDate: moment(dateRange[1]).format('YYYY-MM-DD'),
      };
      const res = await this.service.fetchScheduleData(payload);
      return this.formatScheduleResponse(res);
    } catch (error: any) {
      return [];
    }
  }

  async filterSchedule(): Promise<void> {
    this.scheduleData = await this.getScheduleData();
  }

  hasScheduleAt(hour: number, schedule: ScheduleItem[]): ScheduleItem[] {
    return schedule.filter((item) => {
      const bookingStartingHour = moment()
        .startOf('day')
        .add({ minutes: item.fromTime })
        .format('H');
      return bookingStartingHour === hour.toString();
    });
  }

  private formatScheduleResponse(data: ScheduleOriginalData[]): ScheduleData[] {
    const scheduleMap: { [key: string]: ScheduleData['schedule'] } = {};

    data.forEach((item) => {
      const fromTimeParts = item.fromTime.split(':');
      const toTimeParts = item.endTime.split(':');

      const fromTime =
        parseInt(fromTimeParts[0]) * 60 + parseInt(fromTimeParts[1]);
      const toTime = parseInt(toTimeParts[0]) * 60 + parseInt(toTimeParts[1]);

      const scheduleItem = {
        id: item.id.toString(),
        fromTime,
        toTime,
        address: item.job.resolvedAddress,
        project: item.project.name,
        jobName: item.job.jobName,
        type: item.jobType,
      };

      if (!scheduleMap[item.scheduleDate]) {
        scheduleMap[item.scheduleDate] = [];
      }

      scheduleMap[item.scheduleDate].push(scheduleItem);
    });

    const result = Object.keys(scheduleMap).map((date) => ({
      date,
      schedule: scheduleMap[date],
    }));

    result.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    return result;
  }

  calculateSlotStyle(
    fromTime: number,
    toTime: number,
    currentHour: number
  ): { width: string; left: string; zIndex: number } {
    const diff = toTime - fromTime;
    const currentHourDate = moment().hour(currentHour).minute(0).second(0);
    const startTimeDiff = moment()
      .startOf('day')
      .add(fromTime, 'minutes')
      .diff(currentHourDate, 'minutes');

    const width = (diff / 60) * 100;
    const left = (startTimeDiff / 60) * 100;

    this.fromTime = moment()
      .startOf('day')
      .add(fromTime, 'minutes')
      .format('LT');
    this.toTime = moment().startOf('day').add(toTime, 'minutes').format('LT');

    return { width: `${width}%`, left: `${left}%`, zIndex: currentHour };
  }

  onReschedule(): void {
    this.display = true;
  }

  closeDialog(): void {
    this.display = false;
  }

  onSave(): void {}
}
