import { Component } from '@angular/core';
import { ButtonComponent } from '../../Components/button/button.component';
import { SharedModule } from '../../shared.module';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropDownType, TableCols } from '../../types';
import { InputComponent } from '../../Components/input/input.component';
import { DropdownComponent } from '../../Components/dropdown/dropdown.component';
import { CATEGORIES, DEVICE_TYPE, TASK_COLS } from '../../constants';

@Component({
  selector: 'app-task-management',
  standalone: true,
  providers: [MessageService],
  imports: [ButtonComponent, SharedModule, InputComponent, DropdownComponent],
  templateUrl: './task-management.component.html',
  styleUrl: './task-management.component.scss',
})
export class TaskManagementComponent {
  taskList: any[] = [];
  isLoading: boolean = true;
  formData!: FormGroup;
  skeletonRows = new Array(10);
  onHover: number = 0;
  cols: TableCols[] = TASK_COLS;
  taskDialog: boolean = false;
  onSaveLoad: boolean = false;
  deleteDialog: boolean = false;
  edit: boolean = false;
  deviceType: DropDownType[] = DEVICE_TYPE;
  categories: DropDownType[] = CATEGORIES;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService
  ) {
    this.formData = this.formBuilder.group({
      taskName: ['', Validators.required],
      isReplace: ['', Validators.required],
      deviceType: ['', Validators.required],
      serviceCatalogue: ['', Validators.required],
    });
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  handleAddDialog(): void {
    this.taskDialog = true;
  }

  closeDialog(): void {
    this.taskDialog = false;
  }

  handleEdit(rowData: any): void {}
  handleDelete(rowData: any): void {}
  onSave(): void {}
  onUpdate(): void {}
}
